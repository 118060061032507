<template>
  <div class="service-grid-item">
    <div class="service-grid-item__image">
      <div class="service-grid-item__image-wrapper">
        <img :src="activity.image" class="img-fluid" style="height:230px;width:350px" alt="service thumb" />
      </div>
      <div class="icon">
        <i :class="activity.icon"></i>
      </div>
    </div>
    <div class="service-grid-item__content">
      <h3 class="title">
        <router-link to="/service-details">
          {{ activity.title }}
        </router-link>
      </h3>
      <p class="subtitle">{{ activity.desc }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["activity", "title"],
};
</script>