<template>
    <div class="main-container">

        <Header />

        <Breadcrumb :items="items" title="会员服务" />

        <div class="page-wrapper section-space--inner--120">
            <!--Service section start-->
            <div class="service-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="service-item-wrapper">
                                <div class="row" v-for="item in data.activity" :key="item.id">
                                    <div class="col-lg-12">
                                        <div class="section-title-area text-center">
                                            <h2 class="section-title section-space--bottom--50">
                                                {{ item.activityTitle }}<span class="title-icon"></span>
                                            </h2>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-12 section-space--bottom--30" v-for="list in item.activityList" :key="list.id">
                                        <ActivityItem :activity="list"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Service section end-->
        </div>

        <!--<BrandCarousel addClass="grey-bg" /> --> 

        <Footer />

        <OffCanvasMobileMenu />

        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>
    import data from '../data/service.json'
    import Header from '@/components/Header';
    import Breadcrumb from '../components/Breadcrumb'
    import ActivityItem from '../components/ActivityItem'
    import BrandCarousel from '../components/BrandCarousel'
    import Footer from '../components/Footer'
    import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu';
    export default {
        components: {
            Header,
            Breadcrumb,
            ActivityItem,
            BrandCarousel,
            Footer,
            OffCanvasMobileMenu
        },
        data() {
            return {
                data,
                items: [
                    {
                        text: '首页',
                        to: "/"
                    },
                    {
                        text: '会员服务',
                        active: true
                    }
                ]
            }
        },
        metaInfo: {
            title: '恋胜 - 会员服务',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        },
    }
</script>

